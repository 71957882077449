<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Klasifikasi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Klasifikasi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Klasifikasi",
          href: "/master/Klasifikasi",
        },
        {
          text: "Tambah Klasifikasi",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      master_role: ["one", "two", "three"],
      role_selected: "",
      klasifikasi: "",
    };
  },
  mounted() {},
  methods: {
    resetForm() {
      this.klasifikasi = "";
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("klasifikasi", self.klasifikasi);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/klasifikasi/store",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          if(response_data.code != 200){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          }else{
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master klasifikasi segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$router.push({ name: "all-klasifikasi" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                    label="Klasifikasi"
                    label-for="formrow-klasifikasi-input"
                  >
                    <b-form-input
                      id="formrow-klasifikasi-input"
                      type="text"
                      v-model="klasifikasi"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <b-button type="submit" variant="primary" class="m-1"
                ><i class="fa fa-save"></i> Simpan</b-button
              >
              <b-button type="reset" @click="resetForm" variant="danger" class="m-1"
                ><i class="fa fa-refresh"></i> Reset</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
